import { BaseModel } from "./baseModel";

export class AcessoIpAppModel extends BaseModel {
  dlIpAddress?: string;
  nmRegiaoLocalizacao?: string;
  sgEstadoLocalizacao?: string;
  flAceiteDesempenho?: number;
  flAceitePublicidade?: number;
  dsPontoAcesso?: string;
  dsDetalheAcesso?: string;
  dlDetalheAcessoUid?: string;
  cdVersao?: string;
}
